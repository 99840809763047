.commentSkeleton {
    display: flex;
    margin-bottom: 20px;
    animation: pulse 1.5s infinite;
  }
  
  .avatarSkeleton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-secondary-text);
    margin-right: 10px;
  }
  
  .contentSkeleton {
    flex: 1;
  }
  
  .nameSkeleton {
    width: 100px;
    height: 15px;
    background-color: var(--color-secondary-text);
    margin-bottom: 10px;
  }
  
  .textSkeleton {
    width: 100%;
    height: 40px;
    background-color: var(--color-secondary-text);
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }