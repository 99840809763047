/* src/styles/modules/NavBarMobile.module.css */

.NavBarMobile {
  background-color: var(--color-secondary-background);
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}

.NavItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NavItem span {
  font-size: 12px;
  margin-top: 4px;
  color: var(--color-secondary-text) !important;
}


.NavLink {
  color: var(--color-secondary-text);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 80px;
  transition: color 0.3s ease;
  font-size: xx-large;
}

.NavLink span {
  font-size: 12px;
  margin-top: 5px;
}

.NavLink i {
  font-size: 20px;
}

.NavLink:hover {
  color: var(--color-primary-text);
}

.Active {
  color: var(--color-background);
  background-color: var(--color-accent);
  border-radius: 50px;
}

.Active:hover {
  color: var(--color-primary-text);
}

.ProfileDropdown {
  display: flex;
  align-items: center;
}

.ProfileDropdown .dropdown-toggle::after {
  display: none;
}

.ProfileDropdown .dropdown-menu {
  min-width: 100%;
  text-align: center;
}

.ProfileDropdown .dropdown-item {
  padding: 0.5rem 1rem;
}