.popularProfilesSkeleton {
    background-color: var(--color-secondary-background);
    padding: 20px;
    border-radius: 4px;
  }
  
  .titleSkeleton {
    width: 60%;
    height: 24px;
    background-color: var(--color-secondary-text);
    margin-bottom: 20px;
  }
  
  .profileItemSkeleton {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .avatarSkeleton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-secondary-text);
    margin-right: 10px;
  }
  
  .nameSkeleton {
    width: 100px;
    height: 16px;
    background-color: var(--color-secondary-text);
  }
  
  .titleSkeleton, .avatarSkeleton, .nameSkeleton {
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }