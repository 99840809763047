.profileSkeleton {
    background-color: var(--color-secondary-background);
    padding: 20px;
    border-radius: 4px;
  }
  
  .headerSkeleton {
    display: flex;
    margin-bottom: 20px;
  }
  
  .avatarSkeleton {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--color-secondary-text);
    margin-right: 20px;
  }
  
  .infoSkeleton {
    flex-grow: 1;
  }
  
  .nameSkeleton {
    width: 150px;
    height: 24px;
    background-color: var(--color-secondary-text);
    margin-bottom: 10px;
  }
  
  .statsSkeleton {
    display: flex;
    justify-content: space-between;
  }
  
  .statItemSkeleton {
    width: 80px;
    height: 40px;
    background-color: var(--color-secondary-text);
  }
  
  .bioSkeleton {
    width: 100%;
    height: 60px;
    background-color: var(--color-secondary-text);
    margin-bottom: 20px;
  }
  
  .postsSkeleton {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .postSkeleton {
    width: 100%;
    padding-bottom: 100%;
    background-color: var(--color-secondary-text);
  }
  
  .avatarSkeleton, .nameSkeleton, .statItemSkeleton, .bioSkeleton, .postSkeleton {
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }