.Button {
  font-size: 0.8rem;
  align-self: center;
  border-radius: 100px;
  border-color: var(--color-primary-text);
  padding: 0px;
  margin-left: 0;
  margin-right: 0;
  min-width: 39px;
  color: var(--color-primary-text);
}

.Button:hover {
  opacity: 0.8;
  border-color: var(--color-accent);
  color: var(--color-accent);
  cursor: pointer;
}


.Wide {
  padding: 10px 50px;
  width: 100%;
}

.SocialButton:hover {
  color: var(--color-background) !important;
}

.UndoButton:hover {
  border: 2px solid var(--color-primary-text) !important;
}