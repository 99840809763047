.ProfileImage {
  object-fit: cover;
  height: 120px;
  width: 120px;
  margin: 4px;
}

@media screen and (max-width: 991px) {
  .ProfileImage {
    width: 250px;
    height: 250px;
  }
}

.ProfileOwner {
  color: var(--color-primary-text);
  font-size: 1.5rem;
  font-weight: bold;
}

.ProfileStats {
  color: var(--color-primary-text);
}

.ProfileStats div {
  color: var(--color-secondary-text);
  font-size: 1rem;
}

.ProfileContent {
  color: var(--color-primary-text);
  font-size: 1rem;
  margin-top: 10px;
}

.ProfileButton {
  margin-top: 10px;
  color: var(--color-primary-text);
  background-color: var(--color-secondary-background);
  border: 1px solid var(--color-secondary-text);
}

.ProfileButton:hover {
  background-color: var(--color-accent);
  color: var(--color-primary-text);
}

.ProfileStatsSection {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.ProfileStatsSection div {
  margin: 0 10px;
  text-align: center;
  color: var(--color-primary-text);
}

.ProfileHeader {
  margin-bottom: 20px;
}

.ProfileOwnerPosts {
  color: var(--color-primary-text);
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}
