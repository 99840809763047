.MessageDetailHeader {
  background-color: var(--color-secondary-background);
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px 0;
  border-radius: 0 0 20px 20px;
}

.MessageDetailHeader h2 {
  color: var(--color-primary-text);
  margin-bottom: 0;
  font-size: 1.2rem;
}

.IconButton {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-secondary-background);
  border-color: var(--color-secondary-text);
  color: var(--color-secondary-text);
}

.IconButton:hover, .IconButton:focus {
  background-color: var(--color-secondary-text);
  border-color: var(--color-secondary-text);
  color: var(--color-accent);
}

@media (max-width: 767px) {
  .MessageDetailHeader {
    text-align: center;
  }

  .MessageDetailHeader h2 {
    margin-bottom: 10px;
  }
}