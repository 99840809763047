

:root {
    /* Dark Theme Colors  (will be dynamically changed in ThemeContext.js)  */
    --color-background: #0A1025;
    --color-secondary-background: #0E2846;
    --color-primary-text: #f0e7e7;
    --color-secondary-text: #B3B3B3;

    --logo-bg-color: var(--color-background);
    --logo-primary-color: #f0e7e7;
    --logo-secondary-color: #1F1F1F;

    /* Accent color (remains constant) */
    --color-accent: #1DB954;
  
    /* Accessibility colors */
    --color-error: #FF5252;
    --color-success: #27c62c;
    --color-info: #019641;

    /* Button Colors */
    --color-button-primary: var(--color-accent); 
    --color-button-secondary: var(--color-secondary-background); 
    --color-button-ignore: var(--color-secondary-background); 
    --color-button-danger: var(--color-error); 
 
    /* Text Input Colors */
    --color-textarea-primary: var(--color-primary-text); 
    --color-textarea-secondary: var(--color-secondary-background);
    --color-textinput-text: var(--color-secondary-background);
   
     /* Fonts */
     --font-primary: 'Gabarito', sans-serif;
     --font-secondary: 'Serifa', sans-serif;
 }