.DropdownItem {
  display: inline-block;
  max-width: 50%;
  padding: 0px;
}

.Absolute {
  position: absolute;
  right: 0;
  z-index: 99;
}

.CustomDropdownMenu {
  background-color: var(--color-secondary-background) !important;
  border: 1px solid var(--color-secondary-text) !important;
  position: relative !important;
  display: flex !important;
  flex-direction: column;
}