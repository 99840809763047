.logoContainer {
    display: flex;
    max-width: 200px;
    margin: auto;
  }
  
  .logoContainer svg {
    width: 100%;
    height: 100%;
  }
  
  .logoBg {
    fill: var(--logo-bg-color);
  }
  
  .logoPrimary {
    fill: var(--logo-primary-color);
  }
  
  .logoSecondary {
    fill: var(--logo-secondary-color);
    filter: brightness(2);
  }