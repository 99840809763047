.Background {
  background-color: var(--color-secondary-background);
  border-radius: 20px;
}

.Title {
  font-size: 2.5rem;
  font-weight: 550  !important;
  margin-bottom: 1rem;
  margin: auto;
}

.Subtitle {
  font-size: 1.2rem;
  color: #a0a0a0;
}

/* Style for the Logo container */
.Background > div > div > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style for the Logo SVG */
.Background svg {
  width: 150px;
  height: 150px;
  fill: #ffffff;  /* Adjust this if you want to change the logo color */
}

@media (max-width: 991px) {
  .Title {
    font-size: 2rem;
  }

  .Subtitle {
    font-size: 1rem;
  }

  .Background svg {
    width: 100px;
    height: 100px;
  }
}