.Row {
  height: calc(100vh - 81px);
}

.Input {
  border: 1px solid var(--color-secondary-text);
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
}

.Header {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: var(--color-info);
}

.Link {
  text-decoration: none;
  text-align: center;
  display: block;
  margin: auto;
}

.Link span {
  color: var(--color-error);
}

.Container {
  padding: 30px 10px;
}

.SignInCol {
  height: 320px;
}

.SignUpCol {
  height: 375px;
}