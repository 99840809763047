
 /* Define Gabarito, Core Sans C and Serifa Font Faces */

 @font-face {
  font-family: 'Gabarito';
  src: url('../fonts/Gabarito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

 @font-face {
  font-family: 'Gabarito';
  src: url('../fonts/Gabarito-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gabarito';
  src: url('../fonts/Gabarito-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gabarito';
  src: url('../fonts/Gabarito-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gabarito';
  src: url('../fonts/Gabarito-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


@font-face {
  font-family: 'Gabarito';
  src: url('../fonts/Gabarito-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gabarito';
  src: url('../fonts/Gabarito-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

 @font-face {
    font-family: 'Core Sans C';
    src: url('../fonts/Fontspring-DEMO-coresansc15.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Core Sans C';
    src: url('../fonts/Fontspring-DEMO-coresansc25.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Core Sans C';
    src: url('../fonts/Fontspring-DEMO-coresansc35.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Core Sans C';
    src: url('../fonts/Fontspring-DEMO-coresansc75.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Core Sans C';
    src: url('../fonts/Fontspring-DEMO-coresansc85.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }
  
  /* Define Serifa Font Faces */
  @font-face {
    font-family: 'Serifa';
    src: url('../fonts/SerifaStd-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Serifa';
    src: url('../fonts/SerifaStd-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Serifa';
    src: url('../fonts/SerifaStd-Italic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Serifa';
    src: url('../fonts/SerifaStd-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
  }