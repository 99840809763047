.NavBarDesktop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 80px;
  background-color: var(--color-secondary-background);
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 1000;
}

.NavLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.NavItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.NavLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 35px;
  color: var(--color-secondary-text);
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-size: 24px;
}

.NavLink:hover {
  background-color: var(--color-primary-background);
}

.Active {
  background-color: var(--color-accent) !important;
  border-radius: 30px !important;
  color: var(--color-background) !important;
}

.NavText {
  margin-top: 0px;
  font-size: 12px;
  color: var(--color-primary-text) !important;
}

.LogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;  
  height: 50px;  
  margin-bottom: 15px;
}

.LogoContainer svg {
  width: 100%;
  height: 100%;
}

.ProfileNavItem { /*special styling for the profile nav item at the bottom */
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  margin-bottom: 0;
}