.postSkeleton {
    background-color: var(--color-secondary-background);
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .skeletonHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .skeletonAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--color-secondary-text);
    margin-right: 10px;
  }
  
  .skeletonUsername {
    width: 100px;
    height: 20px;
    background-color: var(--color-secondary-text);
  }
  
  .skeletonBody {
    width: 100%;
    height: 200px;
    background-color: var(--color-secondary-text);
    margin-bottom: 20px;
  }
  
  .skeletonFooter {
    display: flex;
    justify-content: space-between;
  }
  
  .skeletonAction {
    width: 80px;
    height: 30px;
    background-color: var(--color-secondary-text);
  }
  
  .skeletonAvatar, .skeletonUsername, .skeletonBody, .skeletonAction {
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.6;
    }
  }