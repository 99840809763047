.Form {
  border: none;
  border-bottom: 2px solid var(--color-info);
}

.Button {
  border: none;
  background-color: var(--color-secondary-background);
  color: var(--color-primary-text);
  border-radius: 100px;
  padding: 4px 10px;
  min-width: 75px;
  margin: 5px;
}

.Button:hover {
  cursor: pointer;
  background-color: var(--color-primary-text);
  color: var(--color-secondary-background);
}
