@media screen and (max-width: 768px) {
  .Message {
    min-width: 75% !important;
  }
}

.Message {
  max-width: 75%;
  min-width: 55%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  word-wrap: break-word;
  background-color: var(--color-secondary-background);
  color: var(--color-primary-text);
}

.senderMessage {
  margin-left: auto;
  background-color: var(--color-secondary-background);
  filter: brightness(1.2) hue-rotate(20deg) contrast(0.8);
  color: var(--color-primary-text);
  right: 10px;
}

.Message.senderMessage.Begin {
  border-radius: 10px 0px 10px 10px !important;
}

.Message.recipientMessage.Begin {
  border-radius: 0px 10px 10px 10px !important;
}

/* Ensure the triangle is visible for messages with Begin class */
.Begin .MessageTriangle {
  display: block !important;
}

/* Hide the triangle for messages without Begin class */
.Message:not(.Begin) .MessageTriangle {
  display: none !important;
}

.recipientMessage {
  margin-right: auto;
  left: 10px;
}

.MessageContent {
  position: relative;
}

.EditTextarea {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: var(--color-secondary-background);
  color: var(--color-primary-text);
}

.EditButtons {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.MessageImage {
  max-width: 100%;
  border-radius: 8px;
  margin-top: 8px;
}

.MessageMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  font-size: 0.8em;
}

.Timestamp {
  color: var(--color-secondary-text);
}

.MessageActions {
  display: flex;
  gap: 5px;
}

.AvatarLink {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  text-decoration: none;
  color: inherit;
}

.Username {
  margin-left: 5px;
  font-weight: bold;
}

.ImageContainer {
  position: relative;
  cursor: pointer;
}

.ImageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.ImageContainer:hover .ImageOverlay {
  opacity: 1;
}

.ImageOverlay span {
  background-color: var(--color-background);
  color: var(--color-primary-text);
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: bold;
}

.MessageMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  font-size: 0.8em;
  position: relative;  /* Add this */
}

/* Styles for the MessageDetailDropdown */
:global(.dropdown) {
  position: static;  /* Change from absolute to static */
}

:global(.dropdown-toggle) {
  background: none;
  border: none;
  color: var(--color-secondary-text);
  padding: 0;
  font-size: 1em;
}

:global(.dropdown-toggle)::after {
  display: none;
}

:global(.dropdown-menu) {
  position: absolute;
  top: 100%;  /* Position directly below the toggle */
  right: 0;
  min-width: auto;
  background-color: var(--color-background);
  border: 1px solid var(--color-secondary-text);
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;  /* Ensure it's above other content */
}

:global(.dropdown-item) {
  color: var(--color-primary-text);
  font-size: 0.9em;
  padding: 5px 10px;
}

:global(.dropdown-item:hover) {
  background-color: var(--color-secondary-background);
}