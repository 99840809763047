/* src/styles/modules/NavBarMore.module.css */
.NavBarMore {
  position: absolute;
  bottom: 110px;
  right: 20px;
  background-color: var(--color-secondary-background);
  border-radius: 15px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.NavBarMoreDesktop { /* Styles for NavBarMore when used in NavBarDesktop */
  position: absolute;
  bottom: 10px;
  left: 100px;
  background-color: var(--color-secondary-background);
  border-radius: 15px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 165px;
}

.NavItem {
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--color-secondary-text) !important;
  text-decoration: none;
  border-radius: 25px;
  transition: background-color 0.3s, color 0.3s;
}

.NavItemHovered {
  background-color: var(--color-accent);
  color: var(--color-background) !important;
}

.NavItemSpan {
  margin-left: 10px;
  transition: color 0.3s;
  color: var(--color-secondary-text) !important;
}

.NavItemHovered .NavItemSpan {
  color: var(--color-background) !important;
}