.MessageItem {
  background-color: var(--color-secondary-background);
  color: var(--color-primary-text);
  padding: 10px;
  border-bottom: 1px solid var(--color-secondary-text);
  border-radius: 20px;
  transition: background-color 0.3s ease;
  display: block;
}

.MessageItem:hover {
  background-color: var(--color-background);
}

.MessageUsername {
  color: var(--color-primary-text);
  font-weight: bold;
}

.LastMessage {
  color: var(--color-secondary-text);
  font-size: 0.9rem;
  margin: 0;
}

.LastMessageTime {
  color: var(--color-secondary-text);
  font-size: 0.8rem;
  margin: 0;
}

@supports (-webkit-overflow-scrolling: touch) {
  .MessageItem {
    -webkit-overflow-scrolling: touch;
  }
}