.Post {
  background-color: var(--color-secondary-background);
  border: 3px solid var(--color-secondary-text);
  border-radius: 20px;
  margin-bottom: 16px;
  text-align: center;
  position: relative;
}

.Post figure,
h5 {
  margin: 0;
}

.Heart {
  color: var(--color-error);
}

.Heart:hover {
  color: var(--color-info);
}

.HeartOutline:hover {
  color: var(--color-error);
}

.PostBar {
  color: var(--color-secondary-text);
}

.Date {
  color: var(--color-secondary-text) !important;
  font-style: italic !important;
}

.PostImage {
  border-radius: 50px;
}

.PostTitle, .PostText {
  color: var(--color-secondary-text);
}

.PostImageContainer {
  position: relative;
  overflow: hidden;
  padding: 30px;
}

.loadingNotice {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}