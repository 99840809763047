.Owner {
  font-weight: bold;
  color: var(--color-info);
  margin-right: 8px;
}

.Date {
  font-weight: lighter;
  color: var(--color-secondary-text);
}
